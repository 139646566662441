<template>
  <div class="best20-image">
    <ai-breadcrumb />
    <div class="new-button">
      <guide class="guide-button" :disabled="true" />
    </div>
    <div class="search-content">
      <el-form :inline="true" size="small" class="search-form">
        <el-form-item
          class="search-form-gutter"
          :label="$t('analysis.platform')"
        >
          <el-select
            style="width: 88px"
            v-model="currentPlatform"
            @change="changePlatform"
            filterable
          >
            <el-option
              v-for="item in platformList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('analysis.date')">
          <el-select style="width: 128px" v-model="currentDate">
            <el-option
              v-for="item in dateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('common.item')">
          <el-select style="width: 158px" v-model="currentItem" filterable>
            <el-option
              v-for="t in itemList"
              :key="t.value"
              :label="t.label"
              :value="t.value"
              :disabled="t.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            circle
            @click="fetchData"
            :disabled="!currentDate || !currentItem"
          ></el-button>
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-tooltip
            class="item"
            effect="dark"
            content="Download Excel"
            placement="bottom"
          >
            <el-button
              :disabled="!lastQuery"
              size="small"
              :circle="true"
              type="success"
              class="excel-dl-btn"
              @click="clickDownload"
              icon="el-icon-download"
            ></el-button>
          </el-tooltip>
        </el-form-item>
      </el-form>
    </div>
    <div v-loading="best10ImagesLoading" class="best10-images">
      <div
        v-if="!best10ImagesLoading && recentlyList && recentlyList.length > 0"
      >
        <el-row :gutter="4" v-for="(recently, idx) in recentlyList" :key="idx">
          <el-col class="best10-images-brand" :lg="3">
            <div class="best10-images-brand-content">
              <el-tag type="info" size="small">{{ $t("common.brand") }}</el-tag>
              <el-link :underline="false" type="info">{{
                recently ? recently.brand : ""
              }}</el-link>
            </div></el-col
          >
          <el-col :lg="3"><br /></el-col>
          <el-col
            :lg="2"
            v-show="currentBrandImagesIdx[idx] === 0"
            v-for="(item, itemIdx) in recently && recently.urls
              ? recently.urls.slice(0, 10)
              : []"
            :key="itemIdx"
          >
            <transition name="fade">
              <router-link
                target="_blank"
                :to="{
                  path: '/services/analysis/style-search',
                  query: { q: item.key, platform: currentPlatform },
                }"
              >
                <el-image :src="item.url" width="10px" fit="contain" />
              </router-link>
            </transition>
          </el-col>
          <el-col
            :lg="2"
            v-show="currentBrandImagesIdx[idx] === 1"
            v-for="(item, itemIdx) in recently && recently.urls
              ? recently.urls.slice(10, 20)
              : []"
            :key="idx + '-' + itemIdx"
          >
            <transition name="fade">
              <router-link
                target="_blank"
                :to="{
                  path: '/services/analysis/style-search',
                  query: { q: item.key, platform: currentPlatform },
                }"
              >
                <el-image :src="item.url" width="10px" fit="contain" />
              </router-link>
            </transition>
          </el-col>
          <el-col class="best10-images-ctl" :lg="1">
            <svg-icon
              v-if="
                recently.urls &&
                recently.urls.length > 10 &&
                currentBrandImagesIdx[idx] === 0
              "
              @click="() => clickNext(idx)"
              class="next-pre-btn"
              icon-class="next-btn"
            />
            <svg-icon
              v-if="
                recently.urls &&
                recently.urls.length > 10 &&
                currentBrandImagesIdx[idx] === 1
              "
              @click="() => clickPre(idx)"
              class="next-pre-btn"
              icon-class="pre-btn"
            />
          </el-col>
        </el-row>
      </div>
      <div v-else class="primary-no-data">
        <span>No Data</span>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchBest20Image, fetchRecords, fetchUserItems } from "@/api/analysis";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Guide from "@/components/Guide";
import { downloadFileAuth } from "@/utils/download-file";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";

export default {
  components: { AiBreadcrumb, Guide },
  data() {
    return {
      currentPlatform: "tmall",
      platformList: [
        { key: "tmall", value: "TMall" },
        { key: "tiktok", value: "Tiktok" },
      ],
      currentDate: null,
      currentItem: null,
      dateList: [],
      itemList: [],
      tableData: [],
      lastQuery: null,
      recently: {
        brand: null,
        urls: [],
      },
      recentlyList: [],
      total: 0,
      currentBrandImagesIdx: [],
      best10ImagesLoading: false,
    };
  },
  created() {
    this.$store.dispatch("category/fresh").then(() => {
      this.resetItemList();
      this.fetchDates();
    });
  },
  methods: {
    resetItemList() {
      let t2Flatten = this.$store.getters.t2Flatten;
      this.itemList = [];
      t2Flatten &&
        t2Flatten.forEach((t) => {
          t.disabled = true;
          this.itemList.push(t);
        });
    },
    fetchDates() {
      this.dateList = [];
      fetchRecords({
        type: "sales",
        period: "week",
        platform: this.currentPlatform,
        limit: -1,
      }).then((response) => {
        if (response.success) {
          response.result &&
            response.result.items &&
            response.result.items.forEach((e) => {
              this.dateList.push({
                value: e.date,
                label: e.linkDate,
              });
            });
          if (this.dateList && this.dateList.length > 0) {
            this.currentDate = this.dateList[0].value;
          }
        }
      });
    },
    fetchItems() {
      this.currentItem = null;
      this.resetItemList();
      fetchUserItems({ dates: this.currentDate }).then((response) => {
        if (response.success && response.result) {
          this.itemList.forEach((e, i) => {
            let disabled = true;
            for (let j = 0; j < response.result.length; j++) {
              const r = response.result[j];
              if (e.value === r) {
                disabled = false;
                break;
              }
            }
            e.disabled = disabled;
            this.$set(this.itemList, i, e);
            if (!disabled && !this.currentItem) {
              this.currentItem = e.value;
            }
          });
        }
      });
    },
    fetchData() {
      if (!this.currentDate || !this.currentItem) {
        return;
      }
      this.best10ImagesLoading = true;
      this.lastQuery = {
        platform: this.currentPlatform,
        date: this.currentDate,
        item: this.currentItem,
      };
      fetchBest20Image({
        platform: this.currentPlatform,
        date: this.currentDate,
        item: this.currentItem,
      })
        .then((response) => {
          if (response.success) {
            this.recentlyList =
              response.result[0] &&
              response.result[0].datas &&
              response.result[0].datas.length > 0
                ? response.result[0].datas
                : [];
            this.currentBrandImagesIdx = [];
            for (let i = 0; i < this.recentlyList.length; i++) {
              this.currentBrandImagesIdx.push(0);
            }
          }
        })
        .finally(() => {
          this.best10ImagesLoading = false;
        });
    },
    clickDownload() {
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.preparingData"),
      });
      let queryStr = new URLSearchParams(this.lastQuery).toString();
      downloadFileAuth(
        aiApiRoot + "/v1/analysis/best20/images/export?" + queryStr,
        "best20-images-" +
          this.lastQuery.platform +
          "-" +
          this.lastQuery.date +
          "-" +
          this.lastQuery.item +
          ".xlsx",
        getToken(),
        () => {
          loading.close();
        }
      );
    },
    clickNext(idx) {
      this.$set(this.currentBrandImagesIdx, idx, 1);
    },
    clickPre(idx) {
      this.$set(this.currentBrandImagesIdx, idx, 0);
    },
    changePlatform() {
      this.fetchDates();
    },
  },
  watch: {
    currentDate() {
      this.fetchItems();
    },
    currentItem() {
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.best20-image {
  .el-col-lg-2-4 {
    width: 1/11 * 100%;
  }

  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;

    .guide-button {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .search-content {
    background-color: #fff;
    padding: 10px 0 0 10px;
    margin-bottom: 10px;

    .el-form-item {
      margin-bottom: 10px;
    }

    .search-form {
      width: auto;

      .search-form-gutter {
        margin-right: 30px;
      }
    }

    .date-vs {
      vertical-align: middle;
      font-size: 14px;
      color: #606266;
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  .best10-images {
    min-height: 100px;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    height: 100%;
    overflow: hidden;
    position: relative;

    .best10-images-brand {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      .best10-images-brand-content {
        .el-tag {
          margin-right: 10px;
        }
        .el-link {
          font-size: x-large;
        }
      }
    }

    .best10-images-ctl {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      cursor: pointer;

      .next-pre-btn {
        width: 100%;
        height: 70px;
      }
    }
  }

  .primary-no-data {
    padding-top: 30px;
    text-align: center;
    span {
      color: #909399;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>